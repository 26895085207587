var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"changelogList"},[(_vm.loading)?_c('LoadingPlaceholder'):[(_vm.$route.query.id)?_c('button',{staticClass:"btn btn-light border rounded mb-2",on:{"click":function($event){return _vm.resetFilter()}}},[_vm._v(" Reset Filter ")]):_vm._e(),_c('Grid',{ref:_vm.kgm_ref,style:({height: 'auto'}),attrs:{"data-items":_vm.kgm_computedGridItems(_vm.changelogs),"columns":_vm.kgm_responsiveColumns(),"filterable":true,"filter":_vm.kgm_filter,"pageable":_vm.kgm_pagable,"page-size":_vm.kgm_take,"skip":_vm.kgm_skip,"take":_vm.kgm_take,"total":_vm.kgm_allGridItems(_vm.changelogs),"sortable":{
        allowUnsort: true,
        mode: _vm.kgm_sortMode
      },"sort":_vm.kgm_sort,"selected-field":"selected"},on:{"rowclick":_vm.kgm_gridOnRowClick,"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.kgm_gridPageChange,"sortchange":_vm.kgm_gridSortChange},scopedSlots:_vm._u([{key:"installationLink",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('router-link',{staticClass:"alt-primary-color",attrs:{"to":("/installation/" + (props.dataItem.installationId) + "/changelog"),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.kgm_getNestedValue(props.field, props.dataItem))+" ")])],1)]}},{key:"changeTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{staticClass:"chgCol"},[_vm._v(" "+_vm._s(props.dataItem.change && props.dataItem.change.length > 300 ? props.dataItem.change.substring(0, 300) : props.dataItem.change)+" "),(props.dataItem.change && props.dataItem.change.length > 300)?_c('p',{staticClass:"alt-primary-color",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openMoreModal(props.dataItem.change)}}},[_vm._v(" more... ")]):_vm._e()])]}},{key:"dateTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',[_vm._v(" "+_vm._s(_vm.dateTime_dateTime(_vm.kgm_getNestedValue(props.field, props.dataItem)))+" ")])]}},{key:"referenceCell",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[(props.dataItem.issue)?_c('a',{staticClass:"alt-primary-color",attrs:{"target":"_blank","href":_vm.jiraUrl(props.dataItem.issue)}},[_vm._v(" "+_vm._s(props.dataItem.issue)+" ")]):_vm._e(),(props.dataItem.fdTicket)?_c('a',{staticClass:"alt-primary-color",attrs:{"target":"_blank","href":("https://alturos.freshdesk.com/a/tickets/" + (props.dataItem.fdTicket))}},[_vm._v(" "+_vm._s(props.dataItem.fdTicket)+" ")]):_vm._e()])]}},{key:"textFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"textFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Value"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('textFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}}])})],_c('SweetModal',{ref:"moreModal",staticClass:"overflowHidden"},[_c('p',{staticStyle:{"white-space":"pre-line","text-align":"initial"}},[_vm._v(" "+_vm._s(_vm.moreText)+" ")]),_c('div',{staticClass:"clearfix"})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }