<template>
  <div class="changelogList">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <button
        v-if="$route.query.id"
        class="btn btn-light border rounded mb-2"
        @click="resetFilter()"
      >
        Reset Filter
      </button>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(changelogs)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(changelogs)"
        :sortable="{
          allowUnsort: true,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="installationLink"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <router-link
              :to="`/installation/${ props.dataItem.installationId }/changelog`"
              class="alt-primary-color"
              target="_blank"
            >
              {{ kgm_getNestedValue(props.field, props.dataItem) }}
            </router-link>
          </td>
        </template>
        <template
          slot="changeTemplate"
          slot-scope="{props}"
        >
          <td class="chgCol">
            {{ props.dataItem.change && props.dataItem.change.length > 300 ? props.dataItem.change.substring(0, 300) : props.dataItem.change }}
            <p
              v-if="props.dataItem.change && props.dataItem.change.length > 300"
              class="alt-primary-color"
              style="cursor: pointer;"
              @click="openMoreModal(props.dataItem.change)"
            >
              more...
            </p>
          </td>
        </template>
        <template
          slot="dateTemplate"
          slot-scope="{props}"
        >
          <td>
            {{ dateTime_dateTime(kgm_getNestedValue(props.field, props.dataItem)) }}
          </td>
        </template>
        <template
          slot="referenceCell"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <a
              v-if="props.dataItem.issue"
              target="_blank"
              class="alt-primary-color"
              :href="jiraUrl(props.dataItem.issue)"
            >
              {{ props.dataItem.issue }}
            </a>
            <a
              v-if="props.dataItem.fdTicket"
              target="_blank"
              class="alt-primary-color"
              :href="`https://alturos.freshdesk.com/a/tickets/${props.dataItem.fdTicket}`"
            >
              {{ props.dataItem.fdTicket }}
            </a>
          </td>
        </template>
        <div
          slot="textFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="textFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Value"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('textFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
      </Grid>
    </template>
    <SweetModal
      ref="moreModal"
      class="overflowHidden"
    >
      <p style="white-space: pre-line; text-align: initial;">
        {{ moreText }}
      </p>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { mapGetters } from 'vuex';
import { urlParseMixin } from '@/mixins/urlParseMixin';
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: "ChangelogList",
  components: {
    SweetModal
  },
  mixins: [
    kendoGridMixin,
    dateTimeMixin,
    urlParseMixin
  ],
  data () {
    return {
      loading: true,
      changelogs: null,
      kgm_take: 50,
      kgm_columns: [
        {
          field: 'date',
          filterable: false,
          filter: 'date',
          title: this.$t('date'),
          cell: 'dateTemplate',
          width: '140px',
          hideOn: ['mdDown']
        },
        {
          field: 'date',
          filterable: false,
          filter: 'date',
          title: this.$t('date'),
          cell: 'dateTemplate',
          width: '90px',
          hideOn: ['lgUp']
        },
        {
          field: 'installationName',
          filterable: true,
          filter: 'text',
          title: 'Installation',
          filterCell: "textFilter",
          width: '250px',
          hideOn: ['mdDown'],
          cell: 'installationLink'
        },
        {
          field: 'installationName',
          filterable: true,
          filter: 'text',
          title: this.$t('creator'),
          filterCell: "textFilter",
          width: '150px',
          hideOn: ['lgUp']
        },
        {
          field: 'creatorName',
          filterable: true,
          filter: 'text',
          title: this.$t('creator'),
          filterCell: "textFilter",
          width: '140px',
        },
        {
          field: 'topic',
          filterable: true,
          filter: 'text',
          filterCell: "textFilter",
          title: this.$t('freshdesk.topic'),
          hideOn: ['mdDown'],
          width: '120px',
        },
        {
          field: 'change',
          filterable: true,
          filter: 'text',
          title: this.$t('changes'),
          filterCell: "textFilter",
          cell: 'changeTemplate',
          minWidth: '400'
        },
        {
          field: 'responsibleCreatorContact',
          filterable: true,
          filter: 'text',
          title: this.$t('responsibleCreatorContact'),
          filterCell: "textFilter",
          hideOn: ['xlDown'],
          width: '140px'
        },        
        {
          filterable: false, 
          title: 'Reference',          
          cell: "referenceCell",
          hideOn: ['lgDown'],
          width: '110px'
        },
      ],
      moreText: null
    }
  },
  computed: {
    ...mapGetters([
      'store_getJiraUrl'
    ]),
  },
  created () {
    this.getChangelogs();
  },
  methods: {
    resetFilter () {
      this.$router.push('/changelogs');
      this.getChangelogs();
    },
    openMoreModal (text) {
      this.moreText = text;
      this.$refs.moreModal.open();
    },
    jiraUrl (endpoint) {
      return this.urlParseMixin_combine(this.store_getJiraUrl, `browse/${ endpoint }`);
    },
    getChangelogs () {
      this.loading = true;
      this.axios.get(`/Installation/GetAllInstallationChangelogs?`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.changelogs = response.data;
        if(this.$route.query.id) {
          this.changelogs = this.changelogs.filter(x => x.id == this.$route.query.id);
        }
      })
      .finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>
<style>
.changelogList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>

<style scoped>
.chgCol {
  white-space: pre-line; 
  overflow-wrap: break-word;
}
</style>